export default {
	data: () => ({
		langEs: {
			title: 'Solicitudes',
			list: 'Lista',
			calendar: 'Calendario',
			confirmation: 'Autoconfirmar',
			table: {
				title: 'Solicitud confirmada',
				name: 'Nombre',
				phone: 'Teléfono',
				data: 'Fecha de registro',
				endData: 'Registro confirmado'
			},
			btn: {
				title: 'Confirmar',
				done: 'Cancelar',
				addUser: 'Añadir registro'
			},
			emptyMessage: 'No tienes nuevas solicitudes',
			msgConfirmate: 'No tienes solicitudes confirmadas'
		}
	})
}
